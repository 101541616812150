<template>
  <b-form-group
    label="İçerik"
    label-for="content"
  >
    <quill-editor
      v-model="dataItem.content"
      :options="editorOption"
      class="bg-white text-black-50"
    >
      <div
        id="content_toolbar"
        slot="toolbar"
      >
        <editor-toolbar />
      </div>
    </quill-editor></b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import EditorToolbar from '@/layouts/components/common/EditorToolbar.vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'Content',
  components: {
    BFormGroup,
    quillEditor,
    EditorToolbar,
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: '#content_toolbar',
        },
        theme: 'snow',
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['estateCategories/dataItem']
    },
  },
}
</script>
