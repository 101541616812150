<template>
  <div>
    <b-card>
      <segments />
      <title-text />
      <summary-content />
      <content-text />
      <ordering-number />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import Segments from '@/views/Admin/Estate_categories/elements/Segments.vue'
import TitleText from '@/views/Admin/Estate_categories/elements/TitleText.vue'
import SummaryContent from '@/views/Admin/Estate_categories/elements/SummaryContent.vue'
import ContentText from '@/views/Admin/Estate_categories/elements/ContentText.vue'
import OrderingNumber from '@/views/Admin/Estate_categories/elements/OrderingNumber.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    Segments,
    TitleText,
    SummaryContent,
    ContentText,
    OrderingNumber,
  },
  computed: {
    itemData() {
      return this.$store.getters['estateCategories/dataItem']
    },
  },
}
</script>
